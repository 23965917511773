.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

i.icon.hidden {
  display: none;
}

#playButton {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform: scale(5, 5);
}

.fullscreen {
  height: 100%;
}

.fullscreen > div {
  top: 66% !important;
}

video {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.logobacking {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  align-items: center;
  background-color: rgb(250, 250, 250);
  border-radius: 50% 50%;
  width: 160px;
  height: 160px;
}

.play {
  order: 0;
  flex: 1 1 auto;
  align-self: auto;
  margin: 5px;
  width: 64px;
  height: 64px;
  fill: black;
}

.stopped {
  order: 0;
  flex: 1 1 auto;
  align-self: auto;
  margin: 5px;
  width: 44px;
  height: 44px;
  fill: #9e9e9e;
}

.logo {
  order: 0;
  flex: 1 1 auto;
  align-self: auto;
  margin: 5px;
  width: 128px;
  height: 128px;
}
.logo path {
  stroke-dasharray: 315;
  stroke-dashoffset: 315;
  animation: dash 1.5s alternate infinite;
  stroke: #9e9e9e;
  stroke: width 1.2px;
  fill: white;
}

.logo g {
  animation: fill-in 1.5s 1.5s alternate infinite;
  fill-opacity: 1;
}

@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes fill-in {
  to {
    fill-opacity: 1;
  }
}

.hidden {
  display: none !important;
}

.landing-inner-container .content {
  /* width: 60%;
  margin: 0 auto; */
  margin-bottom: 48px;
  position: absolute;
  right: 50px;
  bottom: 50px;
  width: 800px;
  text-align: left;
}

/* .content .title {
  font-size: 72px;
  margin-bottom: 32px;
}

.content .description {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
} */

.fullscreen {
  background: #000;
  color: #fff;
}

.fullscreen .content {
  width: 100%;
}

.fullscreen .img-logo-home {
  animation: breathing 3s linear infinite;
  width: 100%;
}

.landing-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: none;
  text-align: center;
  align-items: center;
  justify-content: center;
  background: #000;
  color: #fff;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.landing-inner-container {
  z-index: 2;
}

.landing-inner-container .txt-loading {
  position: absolute;
  right: 50px;
  bottom: 50px;
  width: 800px;
  text-align: left;
}

.mute-unmute-box {
  position: absolute;
  left: 120px;
  bottom: 50px;
}

.mute-unmute-box .ui.button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 8px;
  cursor: unset;
}

.mute-unmute-box .img-mute-icon {
  width: 28px;
  height: 28px;
  cursor: pointer;
  background: red;
  padding: 4px 6px;
  border-radius: 50%;
  margin-right: 12px;
}

.modal-tutorial-video .modal-content {
  background: transparent;
}

.modal-tutorial-video .modal-header {
  border-bottom: none;
}

.modal-tutorial-video .close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: absolute;
  right: -30px;
  top: 0;
  z-index: 999;
  font-size: 2rem;
  font-weight: normal;
  color: #fff;
  opacity: 1;
}

.modal-tutorial-video .close:hover {
  color: #000;
  text-decoration: none;
}

.modal-tutorial-video .modal-body {
  padding: 0px;
}

.modal-tutorial-video iframe {
  border-radius: 20px;
}

@keyframes breathing {
  0% {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
  }

  50% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }

  100% {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
  }
}

@media (max-width: 1366px) {
  .content .title {
    font-size: 64px;
  }
}

@media (max-width: 1024px) {
  .content .title {
    font-size: 48px;
  }
}

@media (max-width: 767px) {
  .content {
    width: 100%;
    padding: 32px;
  }

  .content .title {
    font-size: 48px;
  }

  .landing-container .content {
    width: 100%;
    padding: 64px 64px 16px;
    margin-bottom: 0px;
    left: 0;
  }

  .landing-container .txt-loading {
    width: 100%;
    left: 0;
    padding-left: 64px;
    bottom: 25px;
  }
}