@font-face {
  font-family: Nexa-Bold;
  src: url('assets/fonts/Nexa/Nexa-Bold.otf');
}

@font-face {
  font-family: Nexa-Regular;
  src: url('assets/fonts/Nexa/Nexa-Regular.otf');
}

@font-face {
  font-family: Nexa-Light;
  src: url('assets/fonts/Nexa/Nexa-Light.otf');
}

html {
  height: 100%;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: initial;
  background-image: linear-gradient(to top, #e6e9f0 0%, #eef1f5 100%);
  height: 100%;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

#root {
  height: 100%;
}

.overlay {
  position: absolute;
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
}

.theme_button {
  font-family: Nexa-Regular;
  background: linear-gradient(264.91deg, rgba(72, 145, 255, 0.8) 3.88%, rgba(45, 91, 255, 0.8) 90.73%);
  border: 1px solid rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(27px);
  border-radius: 4px;
  padding: 10px 20px;
}

.theme_button:hover,
.theme_button:focus,
.theme_button:active {
      color: #FFFFFF;
      border-color: #313F7F;
      box-shadow: 0px 0px 20px #313F7F inset;
}

.heading_medium_bold {
  font-family: Nexa-Bold;
  font-style: normal;
  font-size: 40px;
  line-height: 50px;
  font-weight: unset;
}

.heading_small_bold {
  font-family: Nexa-Bold;
  font-style: normal;
  font-size: 24px;
  line-height: 30px;
  font-weight: unset;
}

.body_medium_regular {
  font-family: Nexa-Regular;
  font-size: 20px;
  font-style: normal;
  line-height: 24px;
  font-weight: unset;
}

.label_14_light {
  font-family: Nexa-Light;
  font-size: 14px;
  font-style: normal;
  line-height: 19px;
  font-weight: unset;
}